import React, {Component} from 'react';

class Dashboard extends Component {
    constructor(props) {
        super(props);

    }


    componentDidMount() {

    }


    shouldComponentUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <p>vvnfinis</p>
                </header>
            </div>
        );
    }
}

Dashboard.propTypes = {};

export default Dashboard;