import React, {Component} from 'react';
import '../Book.scss';

class Book extends Component {
    render() {

        const {books} = this.props;

        return books.map(book => (
            <div className="example-2 card" key={book.id}>
                <div className="wrapper" style={{backgroundImage: `url(${book.image})`}}>
                    <div className="header">
                        <div className="date">
                            <span className="day">20</span>
                            <span className="month">Aug</span>
                            <span className="year">2022</span>
                        </div>
                        <ul className="menu-content">
                            {/*<li><a href="#" className="fa fa-bookmark-o"></a></li>*/}
                            <li><a href="#" className="fa fa-heart-o"><span>999</span></a></li>
                            {/*<li><a href="#" className="fa fa-comment-o"><span>3</span></a></li>*/}
                        </ul>
                    </div>
                    <div className="data">
                        <div className="content">
                            <span className="author">{book.author}</span>
                            <h1 className="title"><a href="#">{book.title}</a>
                            </h1>
                            <p className="text">{book.summary.substring(0, Math.min(100, book.summary.substring(0, 100).lastIndexOf(" "))) + "..."}</p>
                            <a href="#" className="button">Read more</a>
                        </div>
                    </div>

                </div>
            </div>
        ));
    }
}

export default Book;