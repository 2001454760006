import React, {Component} from 'react';
import './Book.scss';
import Book from "./book/Book";

class Books extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let books = [
            {
                "id": 1,
                "title": "The Devotion of Suspect X",
                "author": "Higashino Keigo",
                "readDate": "2022-08-20",
                "summary": "Yasuko Hanaoka is a divorced, single mother who thought she had finally escaped her abusive ex-husband Togashi. When he shows up one day to extort money from her, threatening both her and her teenaged daughter Misato, the situation quickly escalates into violence and Togashi ends up dead on her apartment floor. Overhearing the commotion, Yasuko's next door neighbor, middle-aged high school mathematics teacher Ishigami, offers his help, disposing not only of the body but plotting the cover-up step-by-step. ",
                "image": "https://m.media-amazon.com/images/I/41vbw-p2aDL.jpg",
                "heart": 999
            }
        ]

        return (
            <div>
                <div className="row">
                    <Book books={books}></Book>
                </div>
            </div>
        );
    }
}


export default Books;