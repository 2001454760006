import './App.css';
import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Books from "./components/books/Books";
import Dashboard from "./components/dashboard/Dashboard";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/welcome"/>
                </Route>
                <Route path="/welcome">
                    <Dashboard/>
                </Route>
                <Route path="/books">
                    <Books/>
                </Route>

            </Switch>
        </Router>
    );
}

export default App;
